import Decimal from 'decimal.js';
import camelcaseKeys from 'camelcase-keys';

const httpClient = require('@/services/http-client');

export async function getInventoryDocumentsSlipReportData(
  startDate,
  endDate,
  inventoryId,
  documentTypeCode,
) {
  const response = await httpClient.get('/api/inventory-report/inventory-documents-slip', {
    params: {
      startDate,
      endDate,
      inventoryId,
      documentTypeCode,
    },
    timeout: 45 * 1000,
  });

  const reportData = camelcaseKeys(response.data);

  return reportData;
}

export async function getInventoryJournalReportData(
  startDate,
  endDate,
  inventoryId,
  productId = null,
  excludeNoEntries = false,
  separateOnUnitPrice = false,
  perCompanyTotal = false,
  inventoryOrderCriteria = 0,
) {
  const response = await httpClient.get('/api/inventory-report/inventory-journal', {
    params: {
      startDate,
      endDate,
      inventoryId,
      productId,
      excludeNoEntries,
      separateOnUnitPrice,
      perCompanyTotal,
      inventoryOrderCriteria,
    },
    timeout: 45 * 1000,
  });

  const reportData = camelcaseKeys(response.data);

  reportData.forEach((inventory) => {
    Object.assign(inventory, {
      soldInceput: new Decimal(0),
      soldSfarsit: new Decimal(0),
      valoareTotalaIntrari: new Decimal(0),
      valoareTotalaIesiri: new Decimal(0),
    });

    inventory.produse.forEach((produs) => {
      // eslint-disable-next-line no-param-reassign
      produs.totalIntrari = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(miscare.intrare), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.totalIesiri = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(miscare.iesire), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.stocSfarsit = !(produs.miscari && produs.miscari.length)
        ? produs.stocInceput
        : produs.stocInceput + produs.miscari.reduce((acc, miscare) => acc.plus(miscare.diferentaStoc), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.valoareTotalaIntrari = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(new Decimal(miscare.tipMiscare === 'O' ? 0 : miscare.valoareMiscare)), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.valoareTotalaIesiri = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(new Decimal(miscare.tipMiscare === 'I' ? 0 : miscare.valoareMiscare)), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.soldSfarsit = !(produs.miscari && produs.miscari.length)
        ? produs.soldInceput
        : produs.soldInceput + produs.miscari.reduce((acc, miscare) => acc.plus(miscare.diferentaSold), new Decimal(0)).toNumber();

      produs.miscari.forEach((miscare, miscareIdx, arr) => {
        // eslint-disable-next-line no-param-reassign
        miscare.stocCurent = new Decimal(miscareIdx === 0
          ? produs.stocInceput
          : arr[miscareIdx - 1].stocCurent)
          .plus(miscare.diferentaStoc)
          .toNumber();

        // eslint-disable-next-line no-param-reassign
        miscare.soldCurent = new Decimal(miscareIdx === 0
          ? produs.soldInceput
          : arr[miscareIdx - 1].soldCurent)
          .plus(miscare.diferentaSold)
          .toNumber();
      });

      // eslint-disable-next-line no-param-reassign
      inventory.soldInceput = inventory.soldInceput.plus(produs.soldInceput);
      // eslint-disable-next-line no-param-reassign
      inventory.valoareTotalaIntrari = inventory.valoareTotalaIntrari.plus(produs.valoareTotalaIntrari);
      // eslint-disable-next-line no-param-reassign
      inventory.valoareTotalaIesiri = inventory.valoareTotalaIesiri.plus(produs.valoareTotalaIesiri);
      // eslint-disable-next-line no-param-reassign
      inventory.soldSfarsit = inventory.soldSfarsit.plus(produs.soldSfarsit);
    });

    // eslint-disable-next-line no-param-reassign
    inventory.soldInceput = inventory.soldInceput.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.valoareTotalaIntrari = inventory.valoareTotalaIntrari.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.valoareTotalaIesiri = inventory.valoareTotalaIesiri.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.soldSfarsit = inventory.soldSfarsit.toNumber();
  });

  return reportData;
}

export async function getInventoryAssetJournalReportData(
  startDate,
  endDate,
  employeeId,
  productId = null,
  excludeNoEntries = false,
  separateOnUnitPrice = false,
  perCompanyTotal = false,
  inventoryOrderCriteria = 0,
) {
  const response = await httpClient.get('/api/inventory-report/inventory-asset-journal', {
    params: {
      startDate,
      endDate,
      employeeId,
      productId,
      excludeNoEntries,
      separateOnUnitPrice,
      perCompanyTotal,
      inventoryOrderCriteria,
    },
    timeout: 45 * 1000,
  });

  const reportData = camelcaseKeys(response.data);

  reportData.forEach((inventory) => {
    Object.assign(inventory, {
      soldInceput: new Decimal(0),
      soldSfarsit: new Decimal(0),
      valoareTotalaIntrari: new Decimal(0),
      valoareTotalaIesiri: new Decimal(0),
    });

    inventory.produse.forEach((produs) => {
      // eslint-disable-next-line no-param-reassign
      produs.totalIntrari = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(miscare.intrare), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.totalIesiri = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(miscare.iesire), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.stocSfarsit = !(produs.miscari && produs.miscari.length)
        ? produs.stocInceput
        : produs.stocInceput + produs.miscari.reduce((acc, miscare) => acc.plus(miscare.diferentaStoc), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.valoareTotalaIntrari = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(new Decimal(miscare.tipMiscare === 'O' ? 0 : miscare.valoareMiscare)), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.valoareTotalaIesiri = !(produs.miscari && produs.miscari.length)
        ? 0
        : produs.miscari.reduce((acc, miscare) => acc.plus(new Decimal(miscare.tipMiscare === 'I' ? 0 : miscare.valoareMiscare)), new Decimal(0)).toNumber();

      // eslint-disable-next-line no-param-reassign
      produs.soldSfarsit = !(produs.miscari && produs.miscari.length)
        ? produs.soldInceput
        : produs.soldInceput + produs.miscari.reduce((acc, miscare) => acc.plus(miscare.diferentaSold), new Decimal(0)).toNumber();

      produs.miscari.forEach((miscare, miscareIdx, arr) => {
        // eslint-disable-next-line no-param-reassign
        miscare.stocCurent = new Decimal(miscareIdx === 0
          ? produs.stocInceput
          : arr[miscareIdx - 1].stocCurent)
          .plus(miscare.diferentaStoc)
          .toNumber();

        // eslint-disable-next-line no-param-reassign
        miscare.soldCurent = new Decimal(miscareIdx === 0
          ? produs.soldInceput
          : arr[miscareIdx - 1].soldCurent)
          .plus(miscare.diferentaSold)
          .toNumber();
      });

      // eslint-disable-next-line no-param-reassign
      inventory.soldInceput = inventory.soldInceput.plus(produs.soldInceput);
      // eslint-disable-next-line no-param-reassign
      inventory.valoareTotalaIntrari = inventory.valoareTotalaIntrari.plus(produs.valoareTotalaIntrari);
      // eslint-disable-next-line no-param-reassign
      inventory.valoareTotalaIesiri = inventory.valoareTotalaIesiri.plus(produs.valoareTotalaIesiri);
      // eslint-disable-next-line no-param-reassign
      inventory.soldSfarsit = inventory.soldSfarsit.plus(produs.soldSfarsit);
    });

    // eslint-disable-next-line no-param-reassign
    inventory.soldInceput = inventory.soldInceput.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.valoareTotalaIntrari = inventory.valoareTotalaIntrari.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.valoareTotalaIesiri = inventory.valoareTotalaIesiri.toNumber();
    // eslint-disable-next-line no-param-reassign
    inventory.soldSfarsit = inventory.soldSfarsit.toNumber();
  });

  return reportData;
}
